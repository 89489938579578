<template>
	<div class="home">
        <v-container fluid>
            <v-row dense>
                <v-col lg="7" md="7" xs="12" cols="12">
                    <v-card outlined>
                        <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                            <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                                Live Positions - {{ lp.toUpperCase() }}
                                <v-icon color="white" v-on:click="refreshData()">mdi-refresh</v-icon>
                            </div>
                        </v-card-title>
                        <v-data-table dense
                            :headers="lpPositionHeader" :items="lpPosition" :loading="LPloading" :items-per-page="15"
                            loading-text="Loading... Please wait"
                            height="500px" fixed-header :hide-default-footer="true">

                            <template v-slot:header="{ header }">
                                <tr>
                                    <template v-for="(h, index) in header">
                                        <th :key="index">
                                            {{h.text}}
                                        </th>
                                    </template>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
                <v-col lg="5" md="5" xs="12" cols="12">
                    <v-card outlined>
                        <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                            <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            Account Info
                            </div>
                        </v-card-title>

                            <v-data-table dense
                                :headers="AccountInfoHeader" :items="AccountInfo" :loading="loading"
                                loading-text="Loading... Please wait" :items-per-page="-1"
                                height="100%"
                                id="AccountInfoTable"
                                :hide-default-footer="true"
                            >

                            <template v-slot:header="{ header }">
                                <tr>
                                    <template v-for="(h, index) in header">
                                        <th :key="index" v-if="h.text==='LP'" style="position: sticky;">
                                            {{h.text}}
                                        </th>
                                        <th :key="index" v-else>
                                            {{h.text}}
                                        </th>
                                    </template>
                                </tr>
                            </template>

                            <template v-slot:item="item">
                                <tr>
                                    <td>{{ item.item["LP"].toUpperCase() }}</td>
                                    <td>{{ roundDecimal(item.item["Balance"], 2) }}</td>
                                    <td>{{ roundDecimal(item.item["Credit"], 2) }}</td>
                                    <td>{{ roundDecimal(item.item["Unrealized P&L"], 2) }}</td>
                                    <td>{{ roundDecimal(item.item["Equity"], 2) }}</td>
                                    <td>{{ roundDecimal(item.item["Margin"], 2) }}</td>
                                    <td>{{ roundDecimal(item.item["Free Margin"], 2) }}</td>
                                    <td>{{ roundDecimal(item.item["Margin Utilization %"], 4) }}</td>
                                    <td>{{ item.item["created_at"] }}</td>
                                    <td>{{ item.item["updated_at"] }}</td>
                                </tr>
                            </template>
                        </v-data-table>


                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import moment from "moment";
import { lp } from "@components/mixins/lp";
import { snackbar } from "@components/mixins/snackbar";
import * as apiLpAccountInfo from "@components/api/pk/lp/account-info";
import * as apiLpLivePosition from "@components/api/pk/lp/live-position";
moment.locale("en-au");

export default {
    mixins: [permission, helper, lp, snackbar],

    props: ["broker", "lp", "allLpAccounts"],
    data () {
        return {
            AccountInfoHeader: [
                {"text":"LP","value":"LP","width":100},
                {"text":"Balance","value":"Balance","width":140},
                {"text":"Credit","value":"Credit","width":100},
                {"text":"Unrealized P&L","value":"Unrealized P&L","width":140},
                {"text":"Equity","value":"Equity","width":140},
                {"text":"Margin","value":"Margin","width":140},
                {"text":"Free Margin","value":"Free Margin","width":140},
                {"text":"Margin Utilization %","value":"Margin Utilization %","width":140},
                {"text":"Created At","value":"created_at","width":200},
                {"text":"Updated At","value":"updated_at","width":200}
            ],
            AccountInfo: [],

            lpPositionHeader: [
                {"text":"Symbol","value":"Symbol","width":100},
                {"text":"Margin Rate","value":"Margin Rate","width":80},
                {"text":"Position","value":"Position","width":140},
                {"text":"Total VWAP","value":"Total VWAP","width":140},
                {"text":"Margin","value":"Margin","width":140},
                {"text":"Margin Unrealized P&L in Account CCY","value":"Margin Unrealized P&L in Account CCY","width":140},
                {"text":"Unrealized P&L in Quote CCY","value":"Unrealized P&L in Quote CCY","width":140},
                {"text":"Created At","value":"created_at","width":200},
                {"text":"Updated At","value":"updated_at","width":200}
            ],
            lpPosition: [],

            loading: false,
            reloading: false,

            tableAccountInfo: {
                header: [],
                data: []
            },
            LPloading: false, 
            counter: 0,
        };
    },
    computed: {
    },
    watch: {
    },
    methods: {
        outputHeader (header) {
            if (header == "created_at") return "Created At";
            if (header == "updated_at") return "Updated At";
            return header;
        },
        /**
         * Refresh lp account info
         *
         * @return  {[type]}  [return description]
         */
        refreshData () {
            this.AccountInfo = [];
            for (let account of this.allLpAccounts) {
                this.getAccountInfo(account.lp_info_name)
            }
            
            // this.getAccountInfo();
            this.getLpLivePosition();
        },
        
        getAllAccountInfo () {
            this.loading = true;
            this.$emit("sendBack", [this.loading, this.LPloading]);            
            this.AccountInfo = []
            console.log(this.allLpAccounts)
            for (let i = 0 ; i < this.allLpAccounts.length; i++) {
                this.getAccountInfo(this.allLpAccounts[i].lp_info_name, i)
            }
        },
        getAccountInfo (lpname, index) {
            var params = { broker_name: this.broker, lp_name: lpname};
            let self = this
            console.log(params);
            apiLpAccountInfo.fetch(params).then(res => {
                this.createAccountInfoHeader(res.data);
                this.AccountInfo.push(res.data);
            }).then( () => {
                self.counter += 1;
                console.log(this.counter, this.allLpAccounts.length)
                if (self.counter === this.allLpAccounts.length) {
                    self.snackBarSuccess("Load Account Info Successfully");
                    self.loading = false;
                    self.$emit("sendBack", [this.loading, this.LPloading]);
                    self.counter = 0;
                }
            }).catch( (error) => {
                self.loading = false;
                this.$emit("sendBack", [this.loading, this.LPloading]);            
            });
        },
        createAccountInfoHeader (data) {
            // var tempheader = [];
            // console.log(data)
            // for (const key in data) {
            //     let header = { text: key, value: key, width: this.getwidthofstr(key, data[key])} 
            //     tempheader.push(header);
            // }
            // console.log(tempheader)
            // this.AccountInfoHeader = tempheader;
            // console.log("Account Header: ", JSON.stringify(this.AccountInfoHeader))
        },
        getLpLivePosition () {
            var params = { broker_name: this.broker, lp_name: this.lp };
            console.log(this.params);
            this.LPloading = true;
            let self = this;
            apiLpLivePosition.fetch(params).then(res => {
                this.lpPosition = this.roundvalue(res.data);
                this.createLivePositionHeader();
                console.log(res.data)
                this.snackBarSuccess("Load Live Positions Successfully");
            }).then( () => {
                self.snackBarSuccess("Load Live Positions Successfully");
                self.LPloading = false;
                self.$emit("sendBack", [this.loading, this.LPloading]);     
                console.log(res.data)       
            }).catch( (error) => {
                self.LPloading = false;
                self.$emit("sendBack", [this.loading, this.LPloading]);            
            });
        },

        createLivePositionHeader () {
            // var tempheader = [];
            // for (let i = 0; i < this.lpPosition.length; i++) {
            //     let j = 0;
            //     for (const key in this.lpPosition[i]) {
            //         if (!this.filterkey(key)) {
            //             if (i === 0) {
            //                 tempheader.push({ text: key, value: key, width: this.getwidthofstr(key, this.lpPosition[i][key])});
            //             // }                     
            //             } else {
            //                 // console.log(tempheader[j])
            //                 if (tempheader[j].width <  this.getwidthofstr(key, this.lpPosition[i][key])) {
            //                     tempheader[j].width = this.getwidthofstr(key, this.lpPosition[i][key])
            //                 }
            //             }
            //             j++; 
            //         }
            //     }
            // }
            // console.log(tempheader);
            
            // this.lpPositionHeader = tempheader;
            // console.log("Data Structure: ", JSON.stringify(this.lpPositionHeader));
        },
        filterkey (key) {
            const keys = ["LP"];
            return keys.includes(key);
        },
        roundvalue (data) {
            // issue: not rounding
            // only remove the chars after the postition of 2dps
            // not working for % as last character of the string
            console.log(data[0].Margin, typeof data[0].Margin);

            const TWOdp = 2 + 1;
            return data.map(element => {
                for (const key in element) {
                    var value = element[key];
                    if (typeof value === "number") {
                        element[key] = value.toFixed(2);
                    } else if (typeof value !== "string") {
                        continue;
                    } else {
                        const p = value.indexOf(".");
                        if (p !== -1) {
                            element[key] = value.substring(0, p + TWOdp);
                        }
                    }
                }
                return element;
            });
        },
        checkBrokerandLp (selected) {
            if (selected == "") {
                return false;
            }
            return true;
        }
    },

    created () {
        console.log("in created", this.broker, this.lp);
        this.getAllAccountInfo()
        this.getLpLivePosition()
    },
    mounted () {
        console.log("in mounted", this.broker, this.lp, this.AccountInfoHeader);
    },

    watch: {
        lp (nv) {
            this.lp = nv;
            if (this.checkBrokerandLp(nv)) {
                this.lp = nv;
                this.getLpLivePosition();
            }
        }
    }
};
</script>

<style >
#AccountInfoTable > .v-data-table__wrapper > table > thead > tr > th:nth-child(1),
#AccountInfoTable > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1) {
    position: sticky !important; 
    left: 0; 
    z-index: 1;
    background-color:#1E1E1E;
}
</style>
