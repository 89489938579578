<template>
    <span>
        <v-menu offset-y v-if="brokerMap.length > 1">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="warning" dark v-bind="attrs" v-on="on">{{ selectedBroker }}</v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(item, index) in this.brokerMap" :key="index"
                    @click="changeBroker(item.broker, item.broker_id)">
                    <v-list-item-title>{{ item.broker }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-menu offset-y :disabled="loading">
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-0">{{ selectedLp }}</v-btn>
            </template>
            <v-list>
                <v-list-item v-for="(lp, index) in lpAccount" :key="index"
                    @click="changeLp(lp.lp_info_name) ">
                    <v-list-item-title>{{ lp.lp_info_name.toUpperCase() }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </span>
</template>

<script>

import { mapActions, mapState } from "vuex";
// import * as apiLpAccountInfo from "@components/api/pk/lp/account-info";
import * as lpApi from "@components/api/pk/lp/lplist";

export default {
    data () {
        return {
            selectedBrokerId: 2,
            selectedBroker: "",
            selectedLp: "",
            lpAccount: []
        };
    },
    props: {
        // callback: {
        //     type: Function
        // },

        loading: Boolean
    },

    computed: {
        ...mapState(["brokerMap"])
    },

    methods: {
        /**
         * change lp
         * @param   {[type]}  lp  [lp description]
         * @return  {[type]}      [return description]
         */
        changeLp (lp) {
            this.selectedLp = lp;
        },

        /**
         * Event listener on broker id change
         * @param  {[type]} broker    [description]
         * @param  {[type]} broker_id [description]
         * @return {[type]}           [description]
         */
        changeBroker (broker, brokerId) {
            this.selectedBrokerId = brokerId;
            this.selectedBroker = broker;
        },

        getBrokerNameById (id) {
            for (const ele of this.brokerMap) {
                if (ele.broker_id === id) {
                    return ele.broker;
                }
            }
        },

        loadLpAccountInfo () {
            const self = this;
            console.log("in the controller");
            const brokerName = this.getBrokerNameById(self.selectedBrokerId);
            const params = { broker_name: brokerName };

            lpApi.getlist(params).then(res => {
                console.log(res.data);

                self.lpAccount = res.data;
                // console.log(self.lpAccount);

                for (let i = 0; i < res.data.length; i++) {
                    this.selectedLp = res.data[i].lp_info_name;
                    break;
                }

                console.log(this.selectedBroker, this.selectedLp);
                this.$emit("sendBack", this.selectedBroker, this.selectedLp);
            });
            console.log(this.lpAccount);
        }
    },

    created () {
        this.loadLpAccountInfo();
        this.selectedBroker = this.getBrokerNameById(this.selectedBrokerId);
    },

    watch: {
        selectedBrokerId (nv, ov) {
            this.loadLpAccountInfo();
        },
        selectedLp () {
            this.$emit("sendBack", this.selectedBroker, this.selectedLp);
        }
    }

};
</script>
