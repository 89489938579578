export const lp = {
    data () {
        return {
            srw: {
                "Margin Account": { name: "Margin Account", value: "Margin Account", desc: "" },
                Balance: { name: "Balance", value: "Balance", desc: "" },
                "Credit,": { name: "Credit,", value: "Credit,", desc: "" },
                "Unrealized P&L": { name: "Unrealized P&L", value: "Unrealized P&L", desc: "" },
                Equity: { name: "Equity", value: "Equity", desc: "" },
                Margin: { name: "Margin", value: "Margin", desc: "" },
                "Free Margin": { name: "Free Margin", value: "Free Margin", desc: "" },
                "Margin Utilization %": { name: "Margin Utilization %", value: "Margin Utilization %", desc: "" }
            },
            cfh: {
                // AccountId: { name: "Account Id", value: "AccountId", desc: "" },
                AccountName: { name: "Margin Account", value: "AccountName", desc: "" },
                // AccountType: { name: "Account Type", value: "AccountType", desc: "" },
                Balance: { name: "Balance", value: "Balance", desc: "" },
                // ClientId: { name: "Client Id", value: "ClientId", desc: "" },
                CreditLimit: { name: "Credit Limit", value: "CreditLimit", desc: "" },
                Currency: { name: "Currency", value: "Currency", desc: "" }
                // ExternalAccountId: { name: "External Account Id", value: "ExternalAccountId", desc: "" },
                // IsActive: { name: "Is Active", value: "IsActive", desc: "" },
                // SecurityDeposit: { name: "Security Deposit", value: "SecurityDeposit", desc: "" },
                // TradingState: { name: "Trading State", value: "TradingState", desc: "" },
            },
            lmax: {
                balance: { value: "", name: "Balance", desc: "" },
                cash: { value: "", name: "Cash", desc: "" },
                credit: { value: "", name: "Credit", desc: "" },
                currency: { value: "", name: "Currency", desc: "" }
            },
            b2c2: {
                currency: { value: "", name: "Currency", desc: "" },
                equity: { value: "", name: "Equity", desc: "" },
                margin_requirement: { value: "", name: "Margin Requirement", desc: "" },
                margin_usage: { value: "", name: "Margin Usage", desc: "" }
            },
            isprime: {
                groupName: { value: "", name: "Margin Account", desc: "The name of the risk group" },
                // nop: { value: "", name: "Nop", desc: "The current net open position" },
                // nopLimit: { value: "", name: "Nop Limit", desc: "The net open position limit" },
                // nopUtilisation: { value: "", name: "Nop Utilisation", desc: "The percentage utilisation of the net open position limit" },
                // collateral: { value: "", name: "Collateral", desc: "The collateral held" },
                unrealisedPnl: { value: "", name: "Unrealised Pnl", desc: "The unrealised profit and loss accrued" },
                netEquity: { value: "", name: "Equity", desc: "The total net equity held" },
                requirement: { value: "", name: "Requirement", desc: "The margin required" },
                marginExcess: { value: "", name: "Margin Excess", desc: "" },
                preDeliveredCollateral: { value: "", name: "Pre Delivered Collateral", desc: "The collateral held for funding future positions" },
                marginUtilisation: { value: "", name: "Margin Utilisation", desc: "The percentage utilisation of the net equity" }
            },
            fxcm: {
                accountName: { name: "Account Name", desc: "The unique name of the account the position is opened on. The name is unique within the database where the account is stored." },
                accountId: { name: "Margin Account", desc: "The unique identification number of the account the position is opened on. The number is unique within the database where the account is stored." },
                balance: { name: "Balance", desc: "The amount of funds on the account. This amount does not include floating profit and loss" },
                equity: { name: "Equity", desc: "The amount of funds on the account, including profits and losses of all open positions (the floating balance of the account)." },
                // t: { name: "T", desc: "ID number of the table" },
                // ratePrecision: { name: "Rate Precision", desc: "The price precision of the instrument. It defines number of digits after the decimal point in the instrument price quote." },
                usdMr: { name: "USD Mr", desc: "The amount of funds used to maintain all open positions on the account." },
                // mc: { name: "MC", desc: "The limitation state of the account. Each state defines the operations that can be performed on the account. The possible values are: Y – Margin call (all positions are liquidated, new positions cannot be opened). W – Warning of a possible margin call (positions may be closed, new positions cannot be opened). Q – Equity stop (all positions are liquidated, new positions cannot be opened up to the end of the trading day). A – Equity alert (positions may be closed, new positions cannot be opened up to the end of the trading day). N – No limitations (no limitations are imposed on the account operations)." },
                // mcDate: { name: "MC Date", desc: "" },
                usdMr3: { name: "USD Mr3", desc: "The amount of funds used to maintain all open positions on the account with the three-level margin policy." },
                // hedging: { name: "Hedging", desc: "The type of the position maintenance. It defines how trade operations can be performed on the account. The possible values are:Y – Hedging is allowed. In other words, both buy and sell positions can be opened for the same instrument at the same time. To close each buy or sell position, an individual order is required.N – Hedging is not allowed. In other words, either a buy or a sell position can be opened for the same instrument at a time. Opening a position for the instrument that already has open position(s) of the opposite trade operation always causes closing or partial closing of the open position(s).0 – Netting only. In other words, for each instrument there exists only one open position. The amount of the position is the total amount of the instrument, either bought or sold, that has not yet been offset by opposite trade operations.D – Day netting. In other words, for each instrument there exists only one open position. Same as Netting only, but within a trading day. If the position is not offset during the same trading day it is opened, it is closed automatically on simulated delivery date. F – FIFO. Positions open and close in accordance with the FIFO (Firstin, First-out) rule. Hedging is not allowed." },
                usableMargin3: { name: "Usable Margin3", desc: "" },
                usableMarginPerc: { name: "Usable Margin Perc", desc: "" },
                usableMargin3Perc: { name: "Usable Margin3 Perc", desc: "" },
                usableMargin: { name: "Free Margin", desc: "" },
                bus: { name: "Bus", desc: "" },
                dayPL: { name: "Day PL", desc: "The amount of profits and losses (both floating and realized) of the current trading day." },
                grossPL: { name: "Gross PL", desc: "The amount of profits and losses of all open positions on the account" }
            },
            isprimeLivePosition: {
                category: { text: "Category", value: "category", desc: "The instrument category (FX or IndexSwap)" },
                accountNo: { text: "Account No", value: "accountNo", desc: "The Terminus account number" },
                instrument: { text: "Instrument", value: "instrument", desc: "The instrument code" },
                baseQuantity: { text: "Base Quantity", value: "baseQuantity", desc: "The signed open quantity of the base amount (FX)" },
                contraQuantity: { text: "Contra Quantity", value: "contraQuantity", desc: "The signed open quantity of the contra amount (FX)" },
                marketValueUsd: { text: "Market ValueUsd", value: "marketValueUsd", desc: "The USD value of the baseQuantity" },
                exposureUsd: { text: "Exposure Usd", value: "exposureUsd", desc: "The USD exposure of the position for Margin / NOP calculations" },
                unrealisedPnlUsd: { text: "Unrealised Pnl Usd", value: "unrealisedPnlUsd", desc: "The USD value of the unsettled (FX) or intraday (IndexSwap) pnl" }
            },
            fxcmLivePosition: {
                t: { text: "T", align: "left", value: "t", desc: "ID number of the table" },
                ratePrecision: { text: "Rate Precision", align: "left", value: "ratePrecision", desc: "The price precision of the instrument. It defines number of digits after the decimal point in the instrument price quote." },
                tradeId: { text: "Trade Id", align: "left", value: "tradeId", desc: "The unique identification number of the open position. The number is unique within the same database that stores the account the position is opened on." },
                accountName: { text: "Account Name", align: "left", value: "accountName", desc: "The unique name of the account the position is opened on. The name is unique within the database where the account is stored." },
                accountId: { text: "Account Id", align: "left", value: "accountId", desc: "The unique identification number of the account the position is String opened on. The number is unique within the database where the account is stored." },
                roll: { text: "Roll", align: "left", value: "roll", desc: "The cumulative amount of funds that is added the account balance for holding the position overnight." },
                com: { text: "Com", align: "left", value: "com", desc: "The amount of funds subtracted from the account balance to pay for the broker's service in accordance with the terms and conditions of the account trading agreement." },
                open: { text: "Open", align: "left", value: "open", desc: "The price the position is opened at." },
                valueDate: { text: "Value Date", align: "left", value: "valueDate", desc: "The simulated delivery date. The date when the position could be automatically closed. The date is provided in the yyyyMMdd format. It is applicable only for positions opened on accounts with the day netting trading mode. Otherwise, the value of this field is blank." },
                grossPL: { text: "Gross PL", align: "left", value: "grossPL", desc: "The current profit/loss of the position. It is expressed in the account currency" },
                close: { text: "Close", align: "left", value: "close", desc: "The price at which the position can be closed at the moment." },
                visiblePL: { text: "Visible PL", align: "left", value: "visiblePL", desc: "The current profit/loss per one lot of the position. It is expressed in the account currency." },
                isDisabled: { text: "Is Disabled", align: "left", value: "isDisabled", desc: "" },
                currency: { text: "Currency", align: "left", value: "currency", desc: "The symbol of the instrument" },
                isBuy: { text: "Is Buy", align: "left", value: "isBuy", desc: "The trade operation the position is opened by. The possible values are: True – Buy False – Sell" },
                amountK: { text: "Amount K", align: "left", value: "amountK", desc: "The amount of the position in thousand units." },
                currencyPoint: { text: "Currency Point", align: "left", value: "currencyPoint", desc: "" },
                time: { text: "Time", align: "left", value: "time", desc: "The date and time when the position was opened." },
                usedMargin: { text: "Used Margin", align: "left", value: "usedMargin", desc: "" },
                orderId: { text: "Order Id", align: "left", value: "orderId", desc: "The amount of funds currently committed to maintain the position." },
                OpenOrderRequestTXT: { text: "Open Order Request TXT", align: "left", value: "OpenOrderRequestTXT", desc: "The custom identifier of the order the position is opened by" },
                OpenOrderReqID: { text: "Open Order Req ID", align: "left", value: "OpenOrderReqID", desc: "" },
                TradeIDOrigin: { text: "Trade ID Origin", align: "left", value: "TradeIDOrigin", desc: "" },
                child_trailingStop: { text: "Child Trailing Stop", align: "left", value: "child_trailingStop", desc: "" },
                child_trailing: { text: "Child Trailing", align: "left", value: "child_trailing", desc: "" },
                stop: { text: "Stop", align: "left", value: "stop", desc: "The price of the associated stop order (loss limit level)." },
                stopMove: { text: "Stop Move", align: "left", value: "stopMove", desc: "The number of pips the market should move before the stop order moves the same number of pips after it. If the trailing order is dynamic (automatically updates every 0.1 of a pip), then the value of this field is 1. If the order is not trailing, the value of this field is 0." },
                limit: { text: "Limit", align: "left", value: "limit", desc: "The price of the associated limit order (profit limit level)." },
                isTotal: { text: "Is Total", align: "left", value: "isTotal", desc: "Indicates the row is a summary of for whole table." }
            },
            cfhLivePosition: {
                InstrumentSymbol: { text: "Instrument Symbol", value: "InstrumentSymbol", desc: "" },
                NetOpenAmount: { text: "Net Open Amount", value: "NetOpenAmount", desc: "" },
                AverageOpenPrice: { text: "Average Open Price", value: "AverageOpenPrice", desc: "" },
                CalculationPrice: { text: "Calculation Price", value: "CalculationPrice", desc: "" },
                CalculationMidPrice: { text: "Calculation Mid Price", value: "CalculationMidPrice", desc: "" },
                ClosedPL: { text: "Closed PL", value: "ClosedPL", desc: "" },
                OpenPL: { text: "Open PL", value: "OpenPL", desc: "" }
            },
            srwLivePosition: {
                Symbol: { text: "Symbol", value: "Symbol", desc: "" },
                Margin: { text: "Margin", value: "Margin", desc: "" },
                "Rate,": { text: "Rate,", value: "Rate,", desc: "" },
                Position: { text: "Position", value: "Position", desc: "" },
                "Total VWAP": { text: "Total VWAP", value: "Total VWAP", desc: "" },
                "Margin Unrealized P&L in Account CCY": { text: "Margin Unrealized P&L in Account CCY", value: "Margin Unrealized P&L in Account CCY", desc: "" },
                "Unrealized P&L in Quote CCY": { text: "Unrealized P&L in Quote CCY", value: "Unrealized P&L in Quote CCY", desc: "" }
            },
            pmFxcmMapping: {
	            "AUD/CAD": "AUDCAD", "AUD/CHF": "AUDCHF", "AUD/JPY": "AUDJPY", "AUD/NZD": "AUDNZD", "AUD/USD": "AUDUSD", "CAD/CHF": "CADCHF", "CAD/JPY": "CADJPY", "CHF/JPY": "CHFJPY", "EUR/AUD": "EURAUD", "EUR/CAD": "EURCAD", "EUR/CHF": "EURCHF", "EUR/GBP": "EURGBP", "EUR/JPY": "EURJPY", "EUR/NZD": "EURNZD", "EUR/USD": "EURUSD", "GBP/AUD": "GBPAUD", "GBP/CAD": "GBPCAD", "GBP/CHF": "GBPCHF", "GBP/JPY": "GBPJPY", "GBP/NZD": "GBPNZD", "GBP/USD": "GBPUSD", "NZD/CAD": "NZDCAD", "NZD/CHF": "NZDCHF", "NZD/JPY": "NZDJPY", "NZD/USD": "NZDUSD", "USD/CAD": "USDCAD", "USD/CHF": "USDCHF", "USD/JPY": "USDJPY", "USD/HKD": "USDHKD", "XAU/USD": "XAUUSD", "XAG/USD": "XAGUSD", USOil: "USOUSD", UKOil: "UKOUSD", JPN225: "JP225", AUS200: "AUS200", GER30: "DE30", FRA40: "F40", UK100: "UK100", US30: "US30", SPX500: "US500", NAS100: "USTEC", CHN50: "A50", USOilSpot: "USOUSD", "USD/MXN": "USDMXN", "USD/NOK": "USDNOK", "EUR/NOK": "EURNOK", "USD/SEK": "USDSEK", "USD/ZAR": "USDZAR", "USD/TRY": "USDTRY", "EUR/TRY": "EURTRY"
            }
        };
    },
    methods: {
        isNumber (data) {
            return !isNaN(parseFloat(data)) && isFinite(data);
        },
        formatAccountInfo (name, data) {
            const tableData = [];
            const tableHeader = [];
            const obj = {};
            if (name == "fxcm") {
                for (const item in data) {
                    if (!this.fxcm.hasOwnProperty(item)) continue;
                    tableHeader.push({
                        text: this.fxcm[item].name, value: item, align: "left", desc: this.fxcm[item].desc
                    });
                    if (this.isNumber(data[item])) obj[item] = parseFloat(data[item]).toFixed(2);
                    if (!this.isNumber(data[item])) obj[item] = data[item];
                }
            } else if (name == "isprime") {
                for (const item in data) {
                    if (!this.isprime.hasOwnProperty(item)) continue;
                    tableHeader.push({
                        text: this.isprime[item].name, value: item, align: "left", desc: this.isprime[item].desc
                    });
                    if (this.isNumber(data[item])) obj[item] = parseFloat(data[item]).toFixed(2);
                    if (!this.isNumber(data[item])) obj[item] = data[item];
                }
            } else if (name == "cmc") {

            } else if (name == "cfh") {
                for (const item in data) {
                    if (!this.cfh.hasOwnProperty(item)) continue;
                    tableHeader.push({
                        text: this.cfh[item].name, value: item, align: "left", desc: this.cfh[item].desc
                    });
                    if (this.isNumber(data[item])) obj[item] = parseFloat(data[item]).toFixed(2);
                    if (!this.isNumber(data[item])) obj[item] = data[item];
                }
            } else if (name == "lmax") {
                for (const item in data) {
                    tableHeader.push({
                        text: this.lmax[item].name, value: item, align: "left", desc: this.lmax[item].desc
                    });
                    if (this.isNumber(data[item])) obj[item] = parseFloat(data[item]).toFixed(2);
                    if (!this.isNumber(data[item])) obj[item] = data[item];
                }
            } else if (name == "b2c2") {
                for (const item in data) {
                    tableHeader.push({
                        text: this.b2c2[item].name, value: item, align: "left", desc: this.b2c2[item].desc
                    });
                    if (this.isNumber(data[item])) obj[item] = parseFloat(data[item]).toFixed(2);
                    if (!this.isNumber(data[item])) obj[item] = data[item];
                }
            } else if (name == "srw") {
                for (const item in data) {
                    if (!this.srw.hasOwnProperty(item)) continue;
                    tableHeader.push({
                        text: this.srw[item].name, value: item, align: "left", desc: this.srw[item].desc
                    });
                    if (this.isNumber(data[item])) obj[item] = parseFloat(data[item]).toFixed(2);
                    if (!this.isNumber(data[item])) obj[item] = data[item];
                }
            }
            tableData.push(obj);

            return { h: tableHeader, d: tableData };
        },
        formatLivePosition (name, data) {
            console.log(name, " ==> ", data);
            const tableData = data;
            const tableHeader = [];
            if (name == "fxcm") {
                if (data.length > 0) {
                    for (const item in data[0]) {
                        if (!this.fxcmLivePosition.hasOwnProperty(item)) continue;
                        tableHeader.push({
                            text: this.fxcmLivePosition[item].text, align: "left", value: item, desc: this.fxcmLivePosition[item].desc
                        });
                    }
                }
            } else if (name == "isprime") {
                if (data.length > 0) {
                    for (const item in data[0]) {
                        tableHeader.push({
                            text: this.isprimeLivePosition[item].text, align: "left", value: item, desc: this.isprimeLivePosition[item].desc
                        });
                    }
                }
            } else if (name == "cmc") {

            } else if (name == "cfh") {
                if (data.length > 0) {
                    for (const item in data[0]) {
                        tableHeader.push({
                            text: this.cfhLivePosition[item].text, align: "left", value: item, desc: this.cfhLivePosition[item].desc
                        });
                    }
                }
            } else if (name == "srw") {
                if (data.length > 0) {
                    for (const item in data[0]) {
                        console.log(item, " ==> ", this.srwLivePosition.hasOwnProperty(item));
                        if (!this.srwLivePosition.hasOwnProperty(item)) continue;
                        tableHeader.push({
                            text: this.srwLivePosition[item].text, align: "left", value: item, desc: this.srwLivePosition[item].desc
                        });
                    }
                }
            }

            return { h: tableHeader, d: tableData };
        }
    }
};
