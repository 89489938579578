<template>
    <div class="home">
        <v-row><v-col cols="12"></v-col></v-row>
		<v-card outlined >
            <v-row><v-col cols="12"></v-col></v-row>
            <v-row dense>
                <v-col lg="12" md="12" xs="12" cols="12">
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            Symbol/PK
                        </div>
                            <div class="text-right" style="margin-right: 0; width: 50%;">
                            <v-btn :disabled="loading" dense color="primary" @click="editItem()" style="float: right;" class="pr-4"
                            >ADD</v-btn>                        
                        </div>
                    </v-card-title>
                </v-col>
                <!-- <v-col lg="12" md="12" xs="12" cols="12"> -->
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table class="px-4"
                    dense
                    :headers="header"
                    :items="initialdata"
                    item-key="id"
                    :hide-default-footer="false"
                    :items-per-page="15"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :search="search"
                >
                <v-toolbar>
                </v-toolbar>
                    <template v-slot:item.action="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="deleteItem(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                </v-col>
            </v-row>
        </v-card>
        <template>
            <v-dialog v-model="dialogDelete" max-width="450px">
            <v-card>
                <v-card-title class="headline">
                    DELETION
                </v-card-title>
                <v-card-actions>
                    <p class="pl-16">Are you sure you want to delete this item?</p>
                </v-card-actions>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-btn color="green darken-1" text @click="closeDelete">Cancel</v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

        </template>
        <template>
        <v-dialog v-model="dialogEdit" max-width="1200px">
            <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6" >
                        <v-text-field
                            v-model.lazy="editedItem.api_code"
                            label="Api Code"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                        <v-text-field
                            v-model="editedItem.symbol"
                            label="Symbol"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                        <v-text-field
                            v-model="editedItem.pk"
                            label="Pk"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                        <v-text-field
                            v-model="editedItem.ratio"
                            label="Ratio"
                        ></v-text-field>
                    </v-col>
                </v-row>
              </v-container>
                <v-col>
                    <v-alert text class="mt-2 mb-0" color="info" icon="mdi-cloud-alert" border="left">
                        <p class="mb-0"> <strong> Api Code</strong>: Option field, used by particular LP api service </p>
                        <p class="mb-0"> <strong> Symbol</strong>: LP symbol name </p>
                        <p class="mb-0"> <strong> Pk</strong>: Symbol name used by pk </p>
                        <p class="mb-0"> <strong> Ratio</strong>: The factor that used between lp and broker, eg: The volume ratio of FXCM to broker itself is 10:0, then put 10 as value in ratio field</p>
                    </v-alert>
                </v-col>
            </v-card-text>
             <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn color="green darken-1" text @click="save" :loading="saving">
                Save
              </v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        </template>
    </div>
</template>

<script>
import * as mappingAPI from "@components/api/pk/lp/mapping";
import BrokerCmcSwitcher from "./ControlBrokerCmc";
import moment from "moment";
import { snackbar } from "@components/mixins/snackbar";

export default {

    props: {
        callback: {
            type: Function
        },
        broker: String,
        lp: String
    },
    components: {
        BrokerCmcSwitcher
    },

    mixins: [snackbar],

    data () {
        return {
            nameMap: {},
            editedIndex: -1,
            editedItem: {},
            dialogDelete: false,
            saving: false,
            dialogEdit: false,
            loading: false,
            modifying: false,
            initialdata: [],
            header: [],
            createkeys: ["api_code", "symbol", "pk", "ratio"],
            allKeys: ["id", "api_code", "symbol", "pk", "ratio", "created_at", "updated_at"],
            defaultIteam: {
                api_code: "",
                symbol: "",
                pk: "",
                ratio: ""
            },
            maxid: 0,
            emptyrule: [v => v.trim().split(" ").length <= 1 && v !== "" || "Input can not be empty and 1 word only"],
            search: ""
        };
    },

    methods: {
        checkduplicated () {
            for (let i = 0; i < this.initialdata.length; i++) {
                var ele = this.initialdata[i];
                var duplicated = 0;
                this.createkeys.forEach(key => {
                    // console.log(ele[key], this.editedItem[key])
                    if (ele[key] == this.editedItem[key]) {
                        duplicated += 1;
                    }
                });
                if (duplicated === this.createkeys.length) {
                    return true;
                }
            }
            return false;
        },

        checkinputdata () {
            // var constriantsMessage = ''
            var error = [];
            for (let i = 1; i < this.createkeys.length; i++) {
                // console.log(this.editedItem[key])
                if (this.createkeys[i] != "symbol") {
                    if (this.editedItem[this.createkeys[i]] === "" || this.editedItem[this.createkeys[i]] === null) {
                        // console.log('empty')
                        // constriantsMessage += key
                        error.push(this.nameMap[this.createkeys[i]]);
                    }
                }
            }
            var constriantsMessage = "";

            if (error.length > 0) {
                constriantsMessage += "\"";
                constriantsMessage += error.join(", ");
                constriantsMessage += "\"";
                constriantsMessage += " Can Not be Empty";
                constriantsMessage += "\n";
            } else if (isNaN(this.editedItem.ratio)) {
                constriantsMessage += "\"Ratio\" Must be Integer";
            } else if (Number(this.editedItem.ratio) <= 0) {
                constriantsMessage += "\"Ratio\" Can not be 0 or negative";
            }

            return constriantsMessage;
        },

        findMaxId () {
            var res = 0;
            this.initialdata.forEach(ele => {
                if (ele.id > res) {
                    res = ele.id;
                }
            });
            return res += 1;
        },
        /**
         * create a map for front end display and backend key accessing
         */
        createnameMap () {
            this.allKeys.forEach(key => {
                var displayName = key;
                var splited = key.split("_");

                splited = splited.map(ele => {
                    return ele.charAt(0).toUpperCase() + ele.slice(1);
                });
                displayName = splited.join(" ");

                this.nameMap[key] = displayName;
            });
        },
        /**
         * create header for v-data-table
         */
        createHeader () {
            this.header = [];
            for (var key in this.nameMap) {
                this.header.push({ text: this.nameMap[key], value: key });
            }

            if (this.header.length !== 0) {
                this.header.push({ text: "Actions", value: "action", sortable: false });
            }
        },
        /**
         * open dialog for editing
         */
        editItem (item) {
            this.editedIndex = this.initialdata.indexOf(item);
            if (this.editedIndex > -1) {
                this.editedItem = Object.assign({}, item);
            } else {
                this.editedItem = Object.assign({}, this.defaultIteam);
            }
            this.dialogEdit = true;
        },

        /**
         * after modified data save the item
         */
        save () {
            this.saving = true;
            console.log(this.checkduplicated());

            if (this.checkduplicated()) {
                this.snackBarDanger("Duplicate Item Found In The List");
                this.saving = false;
                return;
            }
            var errormessage = this.checkinputdata();
            if (errormessage !== "") {
                this.snackBarDanger(errormessage);
                this.saving = false;
                return;
            }

            var param = {};
            this.createkeys.forEach(key => {
                param[key] = this.editedItem[key];
            });
            param.lp = this.lp;
            param.broker_name = this.broker;

            var datenow = new Date();
            const format = "YYYY-MM-DD HH:mm:ss";
            var dateTime = moment(datenow).format(format);
            // console.log(dateTime);

            this.$store.state.snackcolor = "";
            if (this.editedIndex > -1) {
                // update back end
                param.id = this.editedItem.id;
                mappingAPI.update(param).then(res => {
                });

                // upadte front end
                var updatedData = this.editedItem;
                updatedData.updated_at = dateTime;
                Object.assign(this.initialdata[this.editedIndex], this.editedItem);
            } else {
                mappingAPI.create(param).then(res => {
                    var createdData = this.editedItem;
                    createdData.created_at = dateTime;
                    createdData.updated_at = dateTime;
                    createdData.id = this.findMaxId();
                    this.initialdata.unshift(createdData);
                });
            }
            setTimeout(() => {
                // console.log(this.$store.state.snackcolor)
                this.search = "";
                if (this.$store.state.snackcolor === "red") {
                    this.snackBarDanger("Can Not Add Item At This Lp");
                } else {
                    if (this.editedIndex > -1) {
                        this.snackBarSuccess("Change the Data Suceesfully");
                    } else {
                        this.snackBarSuccess("Create the Data Sucessfully");
                    }
                }
                this.saving = false;
                this.close();
            }, 1500);
        },
        /**
         * close the editing dialog
         */
        close () {
            this.dialogEdit = false;
        },
        /**
         * open dialog for deleting
         */
        deleteItem (item) {
            this.editedIndex = this.initialdata.indexOf(item);
            this.dialogDelete = true;
        },
        /**
         * close deleting dialog
         */
        closeDelete () {
            this.dialogDelete = false;
            this.editedIndex = -1;
        },
        /**
         * confrim deletion
         */
        deleteItemConfirm () {
            var deleteItem = this.initialdata[this.editedIndex];
            var param = { id: deleteItem.id, broker_name: this.broker, lp: this.lp };

            mappingAPI.deleteData(param).then(res => {
            });

            this.initialdata.splice(this.editedIndex, 1);
            this.closeDelete();
        },
        /**
         * get lp summary data
         */
        fetchData (broker, lp) {
            this.loading = true;
            this.$emit("sendBack", this.loading);
            var param = { broker_name: broker, lp: lp };
            mappingAPI.fetch(param).then(res => {
                this.initialdata = res.data;
                // console.log(res.data)
                this.createnameMap();
                this.createHeader();
                if (this.$store.state.snackcolor !== "red") {
                    this.snackBarSuccess("Load Summary Info Successfully");
                }
            });
            setTimeout(() => {
                this.loading = false;
                this.$emit("sendBack", this.loading);
            }, 2000);
        }
    },

    created () {
        this.fetchData(this.broker, this.lp);
    },

    watch: {
        broker (nv) {
            this.broker = nv;
            this.fetchData(this.broker, this.lp);
        },
        lp (nv) {
            this.lp = nv;
            this.fetchData(this.broker, this.lp);
        }
    }
};
</script>
