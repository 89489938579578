<template>
	<div class="home">
		<v-card>
			<v-container fluid>
				<v-row>
                    <v-col cols="12">
                        <template>
                            <span>
                                <v-menu offset-y v-if="brokerMap.length > 1" :disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="warning" dark v-bind="attrs" v-on="on">{{ selectedBroker }}</v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in this.brokerMap" :key="index"
                                            @click="changeBroker(item.broker, item.broker_id)">
                                            <v-list-item-title>{{ item.broker }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-menu offset-y :disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="primary" v-bind="attrs" v-on="on" class="ml-0">{{ selectedLp }}</v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(lp, index) in lpAccount" :key="index"
                                            @click="changeLp(lp.lp_info_name) ">
                                            <v-list-item-title>{{ lp.lp_info_name.toUpperCase() }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </span>
                            <span>
                                <v-btn v-on:click="posRec()" color="primary" :loading="loading" >Position Record</v-btn>
                            </span>
                        </template>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-tabs >
                            <v-tab v-for="(obj, index) in tabs" :key="index" v-on:click="changeView(index)">
                                {{obj.displayName}}
                            </v-tab>

                        </v-tabs>
                    </v-col>
                </v-row>

                <component v-bind:is="tabs[selectedModule].componentName" v-bind:broker="selectedBroker" :allLpAccounts="lpAccount"
                    v-bind:lp="selectedLp" v-if="brokerLploaded" @sendBack='getloading'>
                </component>
            </v-container>
        </v-card>

        <v-dialog v-model="recDialog" :width="dialogWidth" :hide-overlay="true" :persistent="true">
            <v-card>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" style="background: rgb(26, 57, 88);">
                    <v-toolbar-title class="text-h6 white--text pl-0">Position Rec Result</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="white" icon @click="recDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-app-bar>
                
                    <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12"></v-col>
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <h4 class="pa-4">Break</h4>
                            <v-data-table dense :headers="tablePosRecFailed.header" :items="tablePosRecFailed.data"
                                :header-props="{ sortIcon: null }"
                                item-key="symbol" class="elevation-0"
                                :items-per-page="-1" id="home-table" height="376"
                                :hide-default-footer="true" fixed-header>

                                <template v-for="h in tablePosRecFailed.header" v-slot:[`header.${h.value}`]="{ header }">
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">{{ h.text }}</span>
                                        </template>
                                        <span>{{ h.desc }}</span>
                                    </v-tooltip>
                                </template>
                                
                                <template v-slot:item.break="{ item }">
                                    {{ item.sum_lp_position - item.sum_pk_position }}
                                </template>
                            </v-data-table>

                        </v-col>
                    </v-row>

                    <!-- <v-row>
                        <v-col lg="12" md="12" xs="12" cols="12"><h4>Success</h4></v-col>
                        <v-col lg="12" md="12" xs="12" cols="12">
                            <v-data-table dense :headers="tablePosRec.header" :items="tablePosRec.data"
                                :header-props="{ sortIcon: null }"
                                item-key="symbol" class="elevation-0"
                                :items-per-page="-1" id="home-table" height="376"
                                :hide-default-footer="true" fixed-header>

                                <template v-for="h in tablePosRec.header" v-slot:[`header.${h.value}`]="{ header }">
                                    <v-tooltip top >
                                        <template v-slot:activator="{ on }"><span v-on="on">{{ h.text }}</span></template>
                                        <span>{{ h.desc }}</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>

                        </v-col>
                    </v-row> -->

                <!-- </v-card-actions> -->
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import lpinfo from "./component/lpinfo";
import Mapping from "./component/Mapping";
import BrokerCmcSwitcher from "./component/ControlBrokerCmc";
import { mapActions, mapState } from "vuex";
import * as lpApi from "@components/api/pk/lp/lplist";
import * as apiLpPosRec from "@components/api/pk/lp/pos-rec";
import { helper } from "@components/mixins/helper";

export default {
    components: {
        lpinfo,
        Mapping,
        BrokerCmcSwitcher
    },
    mixins: [helper],
    computed: {
        ...mapState(["brokerMap"])
    },
    data () {
        return {
            sortBy: "symbol",
            sortDesc: false,
            tablePosRec: {
                data: [],
                header: []
            },
            tablePosRecFailed: {
                data: [],
                header: []
            },
            recDialog: false,
            dialogWidth: 1700,

            selectedModule: 0,
            selectedBrokerId: 2,
            selectedBroker: "",
            selectedLp: "",
            lpAccount: [],
            loading: false,
            brokerLploaded: false,
            tabs: [
                { displayName: "Lp", componentName: "lpinfo" },
                { displayName: "Mapping", componentName: "Mapping" }
            ]
        };
    },
    methods: {
        changeView (key) {
            this.selectedModule = key;
            console.log(this.selectedModule);
        },
        getloading (loading) {
            console.log(loading);
            if (!Array.isArray(loading)) {
                this.loading = loading
            } else {
                this.loading = loading[0] || loading[1];
            }

        },
        changeLp (lp) {
            this.selectedLp = lp;
        },

        /**
         * Event listener on broker id change
         * @param  {[type]} broker    [description]
         * @param  {[type]} broker_id [description]
         * @return {[type]}           [description]
         */
        changeBroker (broker, brokerId) {
            this.selectedBrokerId = brokerId;
            this.selectedBroker = broker;
        },
        getBrokerNameById (id) {
            for (const ele of this.brokerMap) {
                if (ele.broker_id === id) {
                    return ele.broker;
                }
            }
        },
        loadLpAccountInfo () {
            // clear lpAccount
            this.loading = true;
            this.lpAccount = [];
            this.selectedLp = "";
            console.log(this.brokerMap);
            this.selectedBroker = this.getBrokerNameById(this.selectedBrokerId);

            const params = { broker_name: this.selectedBroker };
            this.brokerLploaded = false;
            lpApi.getlist(params).then(res => {
                this.lpAccount = res.data;
                this.selectedLp = this.lpAccount[0].lp_info_name;
                this.brokerLploaded = true;
                console.log(this.lpAccount)
                console.log(this.selectedBroker, this.selectedLp);
            });
            this.loading = false;
        },
        posRec () {
            const self = this;
            self.loading = true;
            const params = { broker_id: this.selectedBrokerId };
            console.log(params);

            self.tablePosRec.header = [];
            self.tablePosRec.data = [];
            self.tablePosRecFailed.header = [];
            self.tablePosRecFailed.data = [];


            apiLpPosRec.fetch(params).then(res => {
                console.log("Rec Result: ", res.data);

                const recSuccess = res.data.filter(item => item.break == "No");
                const recFailed = res.data.filter(item => item.break == "Yes");

                if (recSuccess.length != 0) {
                    for (const item in recSuccess[0]) {
                        self.tablePosRec.header.push({ text: item.toUpperCase(), value: item, align: "left" });
                    }
                    self.tablePosRec.data = recSuccess;
                }

                if (recFailed.length != 0) {
                    for (const item in recFailed[0]) {
                        self.tablePosRecFailed.header.push({ text: item.toUpperCase(), value: item, align: "left" });
                    }
                    self.tablePosRecFailed.data = recFailed;
                }
                self.recDialog = true;
                self.loading = false

            }).catch(function(error) {
                self.loading = false
            });
        },
    },
    created () {
        this.loadLpAccountInfo();
    },
    watch: {
        selectedBrokerId (nv, ov) {
            console.log(nv, ov)
            this.loadLpAccountInfo();
        }
    }
};
</script>
<style scoped>
</style>
